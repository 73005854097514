import { css } from "glamor"
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { FaUsers, FaUserCog } from "react-icons/fa"
import { MdWork } from "react-icons/md"
import { RiLogoutBoxLine } from "react-icons/ri"
import { ServiceDisplayName, ServiceURI } from "../utils/service-mappings"
import { useUserOrgs } from "../hooks/use-user"
import { Loading } from "../components/loading"

function ServiceIconDropdown(props) {
  const srcPath = `/${props.serviceName}.svg`
  const text = ServiceDisplayName(props.serviceName)
  if (props.link !== "false") {
    var link = ServiceURI(props.serviceName)
  }

  return (
    <DropdownItem className="px-1 p-0">
      <NavLink href={link} rel="noreferrer" target={props.target || "_blank"}>
        <img
          className={`img-fluid d-inline-block mr-2`}
          src={srcPath}
          {...css(styles.serviceIconImage)}
          alt={props.serviceName}
          height="20"
        />
        {text}
      </NavLink>
    </DropdownItem>
  )
}

function AvailableServices() {
  const [serviceList, setServiceList] = useState([])
  const [loadingServices, setLoadingServices] = useState(true)
  const { user } = useAuth0()

  useEffect(() => {
    let mounted = true
    let services = []
    let data = user["https://api.codelinaro.org/services"] || {}
    Object.keys(data).forEach(function (key) {
      if (data[key]["status"] === "active" && key !== "servicedesk") {
        services.push(key)
      }
    })
    services.push("servicedesk")
    if (mounted) {
      setServiceList(services)
      setLoadingServices(false)
    }
    return function cleanup() {
      mounted = false
    }
  }, [user])

  return (
    <>
      {loadingServices ? (
        <DropdownItem>Loading...</DropdownItem>
      ) : !loadingServices && serviceList.length === 0 ? (
        <DropdownItem>An error occured</DropdownItem>
      ) : (
        serviceList.map(s => <ServiceIconDropdown serviceName={s} key={s} />)
      )}
    </>
  )
}

function DropDownItems(props) {
  const { logout } = useAuth0()
  const {
    loading,
    currentOrg: {
      role: { isProjectAdmin, isUserAdmin },
    },
  } = useUserOrgs()

  if (loading) {
    return <Loading />
  }

  return (
    <>
      {isUserAdmin && (
        <DropdownItem tag={Link} to="/secure/useradmin/">
          <FaUsers className=" mr-2" />
          Manage Users
        </DropdownItem>
      )}
      {isProjectAdmin && (
        <DropdownItem tag={Link} to="/secure/projectadmin/">
          <MdWork className="mr-2" />
          Project Control
        </DropdownItem>
      )}
      {isUserAdmin || (isProjectAdmin && <DropdownItem divider />)}
      <DropdownItem header>Account</DropdownItem>
      <DropdownItem tag={Link} to="/secure/settings/">
        <FaUserCog className="mr-2" />
        Account Settings
      </DropdownItem>
      <DropdownItem
        onClick={event => {
          event.preventDefault()
          logout({ returnTo: `${process.env.AUTH0_LOGOUT}` })
        }}
      >
        <RiLogoutBoxLine className="mr-2" />
        Logout
      </DropdownItem>
    </>
  )
}

export default function LoadingTest() {
  const [isOpen, setIsOpen] = useState(false)
  const { isAuthenticated, user } = useAuth0()
  const toggle = () => setIsOpen(!isOpen)

  return (
    <Navbar color="white" className="top_level" light expand="md">
      <NavbarBrand tag={Link} to="/">
        <img {...css(styles.logo)} src={`/Code_Linaro.png`} alt="CodeLinaro logo" />
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <Link
              to="/projects/"
              className="nav-link"
              activeClassName={`${css(styles.activeLink)}`}
            >
              Projects
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/about/" className="nav-link" activeClassName={`${css(styles.activeLink)}`}>
              About
            </Link>
          </NavItem>
          {false ? (
            isAuthenticated ? (
              <>
                <NavItem>
                  <Link
                    to="/dashboard/"
                    className="nav-link"
                    activeClassName={`${css(styles.activeLink)}`}
                  >
                    Dashboard
                  </Link>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Services
                  </DropdownToggle>
                  <DropdownMenu right {...css(styles.dropdown)}>
                    <AvailableServices />
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    {user.name}
                    <img src={user.picture} {...css(styles.avatar)} alt={user.name} />
                  </DropdownToggle>
                  <DropdownMenu right {...css(styles.dropdown)}>
                    <DropDownItems />
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            ) : (
              <NavItem>
                <NavLink href="/dashboard/">LOGIN</NavLink>
              </NavItem>
            )
          ) : (
            <>
              <NavItem className="nav-link">
                Loading...
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                  <span className="sr-only">Loading...</span>
                </span>
              </NavItem>
            </>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  )
}

const styles = {
  logo: {
    height: "35px",
  },
  avatar: {
    height: "27px",
    borderRadius: "15px",
    marginLeft: "5px",
  },
  activeLink: {
    borderBottom: " 5px solid #9c3",
  },
  dropdown: {
    border: "1px solid #9c3",
    borderRadius: "0px",
    WebkitBoxShadow: "0px 0px 20px -7px rgba(0, 0, 0, 0.75)",
    MozBoxShadow: "0px 0px 20px -7px rgba(0, 0, 0, 0.75)",
    boxShadow: "0px 0px 20px -7px rgba(0, 0, 0, 0.75)",
  },
  serviceIconImage: {
    height: "25px",
    marginLeft: "5px",
  },
}
